import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { posthog } from 'posthog-js';
import { ThemeProvider } from '@airbus/components-react';
import { PostHogProvider } from 'posthog-js/react';
import { store } from './store/store';
import AuthenticatedApp from './container/AuthenticatedApp/AuthenticatedApp';

// analytic url with origin
const ANALYTICS_URL = `https://${process.env.REACT_APP_ANALYTICS_URL}/logs`;

posthog.init(
  process.env.REACT_APP_ANALYTICS_KEY || '',
  {
    api_host: ANALYTICS_URL,
    capture_pageview: false,
  },
);

posthog.register({
  'airbus-aspire-code': process.env.REACT_APP_ASPIRE,
  'airbus-app-env': process.env.REACT_APP_STAGE,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <ThemeProvider brand="skywise">
        <Provider store={store}>
          <BrowserRouter>
            <AuthenticatedApp />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </PostHogProvider>
  </React.StrictMode>,
);
