import React from 'react';
import TaskReportAnalysis from '../../components/TaskReportAnalysis/TaskReportAnalysis';
import EconomicAssessment from '../../components/EconomicAssessment/EconomicAssessment';
import UnscheduledEventsToggled from '../../components/UnscheduledEventsToggled/UnscheduledEventsToggled';
import { featureSwitchConfig } from '../../utils/FeatureToggleUtil/FeatureToggleUtil';
import { checkPermissionByType } from '../../utils/userPermissionUtils/userPermissionUtils';
import { MPE_PERMISSION, PREMIUM_PERMISSION, PROGRAM_ANALYSIS_MODULE } from '../../utils/commonUtils/PermissionMapper';

export const tabs = (userType: string) => {
  return {
    tabs: [
      {
        tabName: 'Task Reports Analysis',
        iconName: '',
        route: 'page1',
        componentMaped: 'page1',
        isPremimum: false,
        class: 'task-report-analysis',
        path: 'task-report-analysis/filter-id/:filterId/task-number/:taskNumber',
        element: <TaskReportAnalysis />,
      },
      {
        tabName: 'Unscheduled Events',
        disable: false,
        iconName: '',
        route: 'page2',
        componentMaped: 'page2',
        isPremimum: false,
        class: 'unscheduled-events',
        path: 'unscheduled-events',
        element: <UnscheduledEventsToggled />,
      },
      {
        tabName: 'Economic Assessment',
        iconName: '',
        disable:
          featureSwitchConfig(
            { name: 'EnhancedAnalysis', userType },
            false,
            true,
          )
          && (checkPermissionByType(PROGRAM_ANALYSIS_MODULE, PREMIUM_PERMISSION)
          || checkPermissionByType(PROGRAM_ANALYSIS_MODULE, MPE_PERMISSION)),
        route: 'page3',
        componentMaped: 'page3',
        isPremimum: false,
        class: 'economic-assessment',
        path: 'economic-assessment',
        element: <EconomicAssessment />,
      },
    ],
    oldTabs: [
      {
        tabName: 'Task Reports Analysis',
        iconName: '',
        route: 'page1',
        componentMaped: 'page1',
        isPremimum: false,
        class: 'task-report-analysis',
        path: 'task-report-analysis/filter-id/:filterId/task-number/:taskNumber',
        element: <TaskReportAnalysis />,
      },
      {
        tabName: 'Unscheduled Events',
        iconName: '',
        disable: true,
        route: 'page2',
        componentMaped: 'page2',
        isPremimum: false,
        class: 'unscheduled-events',
        path: 'unscheduled-events',
        element: <UnscheduledEventsToggled />,
      },
      {
        tabName: 'Economic Assessment',
        iconName: '',
        route: 'page3',
        disable:
          featureSwitchConfig(
            { name: 'EnhancedAnalysis', userType },
            false,
            true,
          )
          && (checkPermissionByType(PROGRAM_ANALYSIS_MODULE, PREMIUM_PERMISSION)
          || checkPermissionByType(PROGRAM_ANALYSIS_MODULE, MPE_PERMISSION)),
        componentMaped: 'page3',
        isPremimum: false,
        class: 'economic-assessment',
        path: 'economic-assessment',
        element: <EconomicAssessment />,
      },
    ],
  };
};
