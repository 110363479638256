/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { uniq, flatten } from 'lodash';
import { CHART_TYPE } from '../../utils/chartUtils/chartLib';
import type { ProgressBarStatus } from '../../components/Shared/Card/CardComponent';
import { ENRICH_TASK_REPORT } from '../programAnalysis/constants';
import { checkFeaturePermission } from '../../utils/userPermissionUtils/userPermissionUtils';

const TaskReportByIntervalDefault = {
  finding_range: [],
  finding_type_description: [],
  finding_count: [],
  operator_interval_sfr: [],
  fleet_interval_sfr: [],
};

/**
 * constant to hold the x-axis label
 */
const IntervalGraphAxisLabel: any = {
  FH: 'Flight Hours',
  FC: 'Flight Cycle',
  MONTH: 'Month',
  CUMULATIVE_FH: 'Cumulative Flight Hours',
  CUMULATIVE_FC: 'Cumulative Flight Cycle',
  AGE: 'A/C age in year',
};

const getsufficientDataColorClassname = (value: string | null) => {
  if (value === 'YES') {
    return 'display-font-green';
  } if (value === 'NO') {
    return 'display-font-red';
  }
  return '';
};

/**
 * This function will return the value if user has permission otherwise it will return '-'
 * @param value
 * @returns
 */
export const getValue = (value: string | undefined) => {
  if (!checkFeaturePermission([ENRICH_TASK_REPORT])) {
    return '-';
  }
  if (value === 'N/A' || value === 'nan' || value === undefined) {
    return 'N/A';
  }
  return `${(Number(value)).toFixed(2)}%`;
};

const formatActualIntervalChartDataset = (data: TaskReportsByActualIntervalData) => {
  const mixTypes: any = {};
  if (!data) {
    return { chartData: [], mixTypes };
  }

  const lineGraph: any = {
    'Operator Finding Rate': data.operator_interval_sfr,
    'Worldwide Finding Rate': data.fleet_interval_sfr,
  };
  const graph: any = {
    interval: data.finding_range,
  };

  const dataLength = data.finding_type_description.length;
  // looping outer array elements
  for (let i = 0; i < data.finding_type_description.length; i += 1) {
    // looping inner array elements
    for (let j = 0; j < data.finding_type_description[i].length; j += 1) {
      if (graph[data.finding_type_description[i][j]]) {
        graph[data.finding_type_description[i][j]][i] = data.finding_count[i][j];
      } else {
        graph[data.finding_type_description[i][j]] = Array(dataLength).fill(0);
        graph[data.finding_type_description[i][j]][i] = data.finding_count[i][j];
      }

      mixTypes[data.finding_type_description[i][j]] = CHART_TYPE.BAR;
    }
  }

  delete graph.NA;

  const intervalGraph = { ...graph, ...lineGraph };

  return { chartData: [intervalGraph], mixTypes };
};

const isNumber = (value: any) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value) || value === null) {
    return false;
  }
  return true;
};

export const getTypeCastedToStringRawTaskReport = (data: RawTaskReportsData[]) => {
  if (data?.length) {
    return data.map((item) => {
      return {
        ac_series: item.ac_series?.toString(),
        id_aircraft: item.id_aircraft?.toString(),
        finding_type_description: item.finding_type_description?.toString(),
        finding_description: item?.finding_description?.toString(),
        corrective_action: item.corrective_action?.toString(),
        actual_interval_months: item.actual_interval_months?.toString(),
        actual_interval_flight_hours: item.actual_interval_flight_hours?.toString(),
        actual_interval_flight_cycles: item.actual_interval_flight_cycles?.toString(),
      };
    });
  }
  return [];
};

export const getTaskInfoCardBody = (taskInfo: TaskInfo) => {
  return [{
    label: 'Task Number',
    type: 'Label',
    value: taskInfo?.mpd_task_reference || '',
    minWidthClass: 'min-width-150',
  },
  {
    label: '100% Threshold',
    type: 'Label',
    value: taskInfo?.mpd_100_threshold || '',
    minWidthClass: 'min-width-120',
  },
  {
    label: 'Description',
    type: 'Label',
    value: taskInfo?.mpd_task_description || '',
    minWidthClass: 'min-width-80',
  },
  {
    label: 'Source Task Reference',
    type: 'Label',
    value: taskInfo?.source_document_reference || '',
    minWidthClass: 'min-width-150',
  },
  {
    label: '100% Interval',
    type: 'Label',
    value: taskInfo?.mpd_100_interval || '',
    minWidthClass: 'min-width-120',
  },
  {
    label: 'Task Code',
    type: 'Label',
    value: taskInfo?.mpd_task_code || '',
    minWidthClass: 'min-width-80',
  },
  {
    label: 'Source',
    type: 'Label',
    value: taskInfo?.source_document_type || '',
    minWidthClass: 'min-width-150',
  },
  {
    label: '',
    type: 'Empty',
    value: '',
  },
  {
    label: 'Applicability',
    type: 'Label',
    value: taskInfo?.applicability_text || '',
    minWidthClass: 'min-width-80',
  }];
};

export const getTaskReportAnalysisCardBody = (taskReportAnalysisOperatorCard: TaskReportAnalysisOperatorCard, isTadaOn: boolean) => {
  // Fetching the values of ffr and me from the response (op = value | NA )
  // if isTadaOn is true apply crown and update the tada value, else not
  const ffrValue = taskReportAnalysisOperatorCard?.ffr;
  const meValue = taskReportAnalysisOperatorCard?.me;
  return [
    {
      label: 'Nb. of TR Reported',
      type: 'Label',
      value: taskReportAnalysisOperatorCard?.total_tr || '',
      alignmentClass: '',
      minWidthClass: 'min-width-190',
    },
    {
      label: 'Operator sample finding rate',
      type: 'Label',
      value: (isNumber(taskReportAnalysisOperatorCard?.operator_finding_rate) && taskReportAnalysisOperatorCard?.total_tr)
        ? `${(Number(taskReportAnalysisOperatorCard.operator_finding_rate)).toFixed(1)}%`
        : 'N/A',
      alignmentClass: isTadaOn ? 'left-shift' : '',
      isPremium: !!isTadaOn,
      minWidthClass: 'min-width-210',
    },
    {
      label: 'Sufficient reported data for optimization',
      type: 'Label',
      value: (taskReportAnalysisOperatorCard?.sufficient_reported_data_for_optimization === 'N/A')
        ? 'NO'
        : (taskReportAnalysisOperatorCard?.sufficient_reported_data_for_optimization) || '',
      className: getsufficientDataColorClassname(taskReportAnalysisOperatorCard?.sufficient_reported_data_for_optimization),
      alignmentClass: isTadaOn ? 'left-shift' : '',
      isPremium: !!isTadaOn,
      minWidthClass: 'min-width-260',
    },
    {
      label: 'Nb. of AC Reported',
      type: 'Label',
      value: taskReportAnalysisOperatorCard?.total_ac_reported || '',
      alignmentClass: '',
      minWidthClass: 'min-width-190',
    },
    {
      label: 'Operator enhanced finding rate',
      type: 'Label',
      value: getValue(ffrValue),
      alignmentClass: isTadaOn ? 'left-shift' : '',
      isPremium: !!isTadaOn,
      minWidthClass: 'min-width-210',
    },
    {
      label: '',
      type: 'Progress',
      value: taskReportAnalysisOperatorCard?.sufficent_reported_bar || '0',
      status: taskReportAnalysisOperatorCard?.sufficient_reported_data_for_optimization === 'YES'
        ? 'success'
        : 'error' as ProgressBarStatus,
      tooltipText: `${taskReportAnalysisOperatorCard.total_tr || ''}`,
      tooltipPlacement: 'bottom',
    },
    {
      label: 'Task execution average yield',
      type: 'Label',
      value: (isNumber(taskReportAnalysisOperatorCard?.avg_yield) && taskReportAnalysisOperatorCard?.total_tr)
        ? `${(Number(taskReportAnalysisOperatorCard.avg_yield)).toFixed(1)}%`
        : 'N/A',
      alignmentClass: '',
      minWidthClass: 'min-width-190',
    },
    {
      label: 'Margin of error',
      type: 'Label',
      value: getValue(meValue),
      alignmentClass: isTadaOn ? 'left-shift' : '',
      isPremium: !!isTadaOn,
      minWidthClass: 'min-width-210',
    },
  ];
};

export const getWorldWideFeetCardBody = (taskInfoFleetCard: TaskInfoFleetCard, isTadaOn: boolean) => {
  return [{
    label: 'Worldwide fleet sample   finding rate',
    type: 'Label',
    value: isNumber(taskInfoFleetCard?.world_wide_finding_rate) ? `${(Number(taskInfoFleetCard.world_wide_finding_rate)).toFixed(1)}%` : 'N/A',
    minWidthClass: 'width-190',
  },
  {
    label: 'Worldwide fleet enhanced finding rate',
    type: 'Label',
    value: getValue(taskInfoFleetCard?.world_wide_enhanced_finding_rate),
    minWidthClass: 'width-190',
    isPremium: isTadaOn,
    alignmentClass: isTadaOn ? 'left-shift' : '',
  },
  ];
};

export const getFindingRateByClimateAreasChartData = (data: FindingRateByGeoRegion) => {
  return {
    chartDataset: [{
      'Climatic Areas': data.all_climate_regions,
      'World Wide Sample Finding Rate (%)': data.climate_fleet_finding_rate,
    }],
    labelKey: 'Climatic Areas',
    type: CHART_TYPE.STACKED,
    chartTitle: '',
    details: {
      mixType: {
        'World Wide Sample Finding Rate (%)': CHART_TYPE.BAR,
      },
      axisTitles: ['Climatic Areas', ['World Wide', 'Sample Finding Rate %']],
      hideLegends: true,
    },
  };
};

export const getFleetWideFindingsAndTypeChartData = (data: FleetWideFindingsByFindingType) => {
  return {
    chartDataset: [{
      'Finding Type': data.fleet_finding_list,
      'Average number of findings per A/C': data.fleet_finding_avg_count,
    }],
    labelKey: 'Finding Type',
    type: CHART_TYPE.STACKED,
    chartTitle: '',
    details: {
      mixType: {
        'Average number of findings per A/C': CHART_TYPE.BAR,
      },
      axisTitles: ['Finding Type', 'Average number of findings per A/C'],
      multiColour: true,
      hideLegends: true,
    },
    height: 115,
  };
};

export const getOperatorReportsPerYearChartData = (data: TaskInfoOperatorLevelReportReceivedYearly) => {
  return {
    chartDataset: [{
      Year: data.operator_task_year,
      'Task Reports': data.operator_task_year_count,
    }],
    labelKey: 'Year',
    type: CHART_TYPE.STACKED,
    chartTitle: '',
    details: {
      mixType: {
        'Task Reports': CHART_TYPE.BAR,
      },
      axisTitles: ['Year', 'Task Reports'],
      hideLegends: true,
    },
  };
};

export const getOperatorFindingsAndTypeChartData = (data: TaskInfoOperatorLevelFindingChart) => {
  return {
    chartDataset: [{
      'Finding Type': data.operator_finding_list,
      'Average number of findings per A/C': data.operator_finding_avg_count,
    }],
    labelKey: 'Finding Type',
    type: CHART_TYPE.STACKED,
    chartTitle: '',
    details: {
      mixType: {
        'Average number of findings per A/C': CHART_TYPE.BAR,
      },
      axisTitles: ['Finding Type', 'Average number of findings per A/C'],
      multiColour: true,
      hideLegends: true,
    },
    height: 115,
  };
};

export const getTaskReportAndFindingRateByMSNChartData = (data: TaskInfoOperatorLevelFindingRateByMsn) => {
  return {
    chartDataset: [{
      MSN: data.id_aircraft,
      Findings: data.all_finding_per_id_aircraft_count,
      'NIL Findings': data.nil_finding_per_id_aircraft_count,
      'Finding Rate': data.finding_rate_per_id_aircraft_count,
    }],
    labelKey: 'MSN',
    type: CHART_TYPE.MIX,
    chartTitle: '',
    details: {
      mixType: {
        Findings: CHART_TYPE.BAR,
        'NIL Findings': CHART_TYPE.BAR,
        'Finding Rate': CHART_TYPE.LINE,
      },
      axisTitles: ['MSN', 'Number of tasks reports', 'Finding Rate %'],
      yAxisArray: ['y', 'y'],
      displayYAxis: 'frAxis',
    },
    height: 115,
  };
};

export const getTaskReportsByActualIntervalChartData = (data: TaskReportsByActualIntervalData) => {
  const result = formatActualIntervalChartDataset(data);
  const xAxisLabel = IntervalGraphAxisLabel[`${data?.graph}`] || '';
  return {
    chartDataset: result.chartData,
    labelKey: 'interval',
    type: CHART_TYPE.MIX,
    chartTitle: '',
    details: {
      mixType: {
        'Operator Finding Rate': CHART_TYPE.LINE,
        'Worldwide Finding Rate': CHART_TYPE.LINE,
        ...result.mixTypes,
      },
      axisTitles: [xAxisLabel, 'Finding Types', 'Finding Rate %'],
      yAxisArray: ['y'],
      displayYAxis: 'frAxis',
    },
    height: 115,
  };
};

export const getTaskReportsByCummulativeIntervalChartData = (data: TaskReportsByActualIntervalData) => {
  const result = formatActualIntervalChartDataset(data);
  const xAxisLabel = IntervalGraphAxisLabel[`${data?.graph}`] || '';
  return {
    chartDataset: result.chartData,
    labelKey: 'interval',
    type: CHART_TYPE.MIX,
    chartTitle: '',
    details: {
      mixType: {
        'Operator Finding Rate': CHART_TYPE.LINE,
        'Worldwide Finding Rate': CHART_TYPE.LINE,
        ...result.mixTypes,
      },
      axisTitles: [xAxisLabel, 'Operator Finding Types', 'Finding Rate %'],
      yAxisArray: ['y'],
      displayYAxis: 'frAxis',
    },
    height: 115,
  };
};

export const getTaskReportByIntervalDefaultGraphData = (data: Array<string>) => {
  return data.map((type) => {
    return {
      graph: type,
      ...TaskReportByIntervalDefault,
    };
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const updateActualIntervalInStore = (intervalType: string, results: any) => {
  let initData: any = [];

  if (intervalType === 'actual') {
    initData = getTaskReportByIntervalDefaultGraphData(['FC', 'FH', 'MONTH']);
  } else {
    initData = getTaskReportByIntervalDefaultGraphData(['CUMULATIVE_FC', 'CUMULATIVE_FH', 'AGE']);
  }
  results.forEach((item: any) => {
    const itemPresentAt = initData.findIndex((i: any) => i.graph.toLowerCase() === item.data.type);
    if (item.data.opsfr) {
      initData[itemPresentAt].finding_range = item.data.range;
      initData[itemPresentAt].operator_interval_sfr = item.data.opsfr;
    }

    if (item.data.wwfr) {
      initData[itemPresentAt].fleet_interval_sfr = item.data.wwfr;
    }

    if (item.data.finding_type_description) {
      initData[itemPresentAt].finding_type_description = item.data.finding_type_description;
      initData[itemPresentAt].finding_count = item.data.finding_type_count;
    }
  });
  return initData;
};

export const getDropDownData = (data: TaskReportsByActualIntervalData) => {
  return (data && data?.finding_type_description?.length)
    ? uniq(flatten(data.finding_type_description))
      .filter((item: any) => item !== 'NA')
      .map((value: string) => {
        return { label: value, value };
      }) : [];
};

const removeFindingCountItems = (orginalArr: TaskReportsByActualIntervalData, filterFindingCount: any) => {
  return orginalArr.finding_count.map((item, itemIndex) => {
    if (filterFindingCount.get(itemIndex)) {
      return item.filter((value, index) => filterFindingCount.get(itemIndex).includes(index));
    }
    return [];
  });
};

export const getFilteredChartData = (dataArr: TaskReportsByActualIntervalData[], type: string, filterItems: Array<string>) => {
  return dataArr.map((item) => {
    const filterFindingCount = new Map();
    if (item.graph === type) {
      return {
        ...item,
        finding_type_description: item.finding_type_description.map((ftd, ftdIndex) => {
          const filterIndexex: Array<number> = [];
          return ftd.filter((value, index) => {
            if (filterItems.includes(value)) {
              // eslint-disable-next-line no-param-reassign
              filterIndexex.push(index);
              filterFindingCount.set(ftdIndex, filterIndexex);
              return true;
            }
            return false;
          });
        }),
        finding_count: removeFindingCountItems(item, filterFindingCount),
      };
    }
    return { ...item };
  });
};

export const preparePrecompFilterList = (params: FetchRawTaskReportsParams) => {
  const finalList: string[] = [];
  let filterValues = '';
  if (!params?.searchColumn) {
    return undefined;
  }

  params?.searchColumn.forEach((item: any) => {
    finalList.push(`${item.columnName} eq ${item.columnInput}`);
  });
  filterValues = finalList.join(',');
  return filterValues;
};

export const searchColumnString = (params: FetchRawTaskReportsColumnSearchParams) => {
  let filterString: string;

  if (params?.searchColumn?.columnName === 'actual_interval_months' || params?.searchColumn?.columnName === 'actual_interval_flight_hours' || params?.searchColumn?.columnName === 'actual_interval_flight_cycles') {
    filterString = `${params?.searchColumn.columnName} numlike ${params?.searchColumn.columnInput}`;
  } else {
    filterString = `${params?.searchColumn.columnName} like ${params?.searchColumn.columnInput}`;
  }

  return filterString;
};

// function to give array with values operatortaskmonth/operatortaskyear for operatorLevelReportReceivedYearlyChart -new architecture
export const generateTaskReportMonthYearFormat = (
  operatorTaskYear: Array<number | string>,
  operatorTaskYearCount: Array<number | string>,
  operatorTaskMonth: Array<number | string>,
) => {
  const monthYearArray: Array<number | string> = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < operatorTaskYear.length; i++) {
    if (operatorTaskYearCount[i] === 0) {
      monthYearArray.push(operatorTaskYear[i]);
    } else {
      monthYearArray.push(operatorTaskMonth[i] !== 'NA' ? (`${operatorTaskMonth[i]}/${operatorTaskYear[i]}`) : (`${operatorTaskYear[i]}`));
    }
  }
  return monthYearArray;
};
