import { filtersValues } from '../../models/programAnalysis/programAnalysisTypes';

export const compareArraysUnordered = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

export const deepCompare = (obj1: filtersValues, obj2: filtersValues) => {
  // If both are strictly the same reference or are strictly equal (like numbers, strings, null, etc.)
  if (obj1 === obj2) return true;

  // If either is not an object or is null, return false
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If the objects have different numbers of keys, they are not equal
  if (keys1.length !== keys2.length) return false;

  for (let i = 0; i < keys1.length; i += 1) {
    const key = keys1[i];
    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      if (!compareArraysUnordered(obj1[key], obj2[key])) {
        return false;
      }
    } else if (!deepCompare(obj1[key], obj2[key])) {
      return false;
    }
  }

  // Return false if the comparison failed
  return true;
};
