import {
  filtersValues,
  programAnalysisAction,
  programAnalysisState,
  taskBuildState,
  programAnalysisFetchAction,
  maintenanceTask,
  buildMetadata,
} from './programAnalysisTypes';
import {
  BUILD_ID,
  BUILD_LIMIT_EXCEED,
  BUILD_STATUS,
  CSAF_SOURCE,
  FETCH_BUILD,
  FETCH_BUILD_METADATA,
  FETCH_FILTERS,
  FLEET_AVG_YIELD,
  initialState, IS_CALCULABLE, LSDR_TASK, MAINTENANCE_PROGRAM,
  NUMBER_OF_TASK_REPORT,
  OP_AVG_YIELD,
  OPERATOR_FINDING_RATE,
  OPERATOR_ICAO_CODE, POSSIBLE_BUILD_STATUS,
  POST_FILTERS,
  PROGRAM_ANALYSIS_SLICE, TIME_FRAME_YEAR,
  WORLD_WIDE_FINDING_RATE,
} from './constants';
import { applyFilters } from '../../components/Shared/TableSearch/utils';
import { customFuncs } from '../../components/PAnalysisComponent/TaskList/utils';

/*
  SYNC REDUCERS (NO FETCH)
 */

/**
 * Reducer to manually update filters. Must be used ONLY for selected filters
 * @param state The state object. It is automatically passed by Redux, no need to pass anything here
 * @param action The things you want to change in the store. No need to specify the "payload" key, directly pass the data you want to be mutated
 */
export const updateSelectedFiltersReducer = (
  state: programAnalysisState,
  action: programAnalysisAction,
) => {
  return ({
    ...state,
    filters: {
      ...state.filters,
      selected: action.payload as filtersValues,
    },
  });
};

/**
 * Reducer to manually update tasks object. Shouldn't be used, you should use the async version instead.
 * This version is only here for convenience
 * @param state The state object. It is automatically passed by Redux, no need to pass anything here
 * @param action The things you want to change in the store. No need to specify the "payload" key, directly pass the data you want to be mutated
 */
export const updateTasksObjectReducer = (state: programAnalysisState, action: programAnalysisAction) => ({
  ...state,
  tasks: {
    ...state.tasks,
    ...(action.payload as taskBuildState),
    filteredData: applyFilters(state.tasks.data as maintenanceTask[], action.payload.filters, customFuncs),
  },
});

/**
 * Reducer to clear the store, on the programAnalysis.filters part
 * @param state The state object
 */
export const clearFiltersObjectReducer = (state: programAnalysisState) => ({
  ...state,
  filters: {
    ...initialState.filters,
    fetchInfo: {
      loading: false,
      lastMessage: '',
    },
  },
});

/**
 * Reducer to clear the store, on the programAnalysis.tasks part
 * @param state The state object
 */
export const clearTasksObjectReducer = (state: programAnalysisState) => ({
  ...state,
  tasks: {
    ...initialState.tasks,
    fetchInfo: {
      loading: false,
      lastMessage: '',
    },
  },
});

export const clearBuildInfoObjectReducer = (state: programAnalysisState) => ({
  ...state,
  build: {
    ...initialState.build,
    buildLimitExceed: false,
    fetchInfo: {
      loading: false,
      lastMessage: '',
    },
  },
});
export const clearPrevBuildIdObjectReducer = (state: programAnalysisState) => ({
  ...state,
  performance: {
    ...initialState.performance,
  },
});

export const updateBuildInfoObjectReducer = (state: programAnalysisState, action: programAnalysisAction) => ({
  ...state,
  build: {
    ...state.build,
    ...action.payload,
  },
});

export const updateSelectedTaskReducer = (state: programAnalysisState, action: programAnalysisAction) => ({
  ...state,
  tasks: {
    ...state.tasks,
    selectedTaskReference: action.payload?.selectedTaskReference,
    currentPage: action.payload?.currentPage,
  },
});

export const updateSelectedPageReducer = (state: programAnalysisState, action: programAnalysisAction) => ({
  ...state,
  tasks: {
    ...state.tasks,
    currentPage: action.payload?.currentPage,
  },
});

export const clearSelectedTaskReducer = (state: programAnalysisState) => ({
  ...state,
  tasks: {
    ...state.tasks,
    selectedTaskReference: undefined,
  },
});

export const updateTaskListSortReducer = (state: programAnalysisState, action: programAnalysisAction) => ({
  ...state,
  tasks: {
    ...state.tasks,
    sort: action.payload.sort,
  },
});

/*
  END OF SYNC REDUCERS (NO FETCH)
 */

/*
  ASYNC REDUCERS (DATA FETCHED)
 */

export const updateFetchedFiltersReducer = (state: programAnalysisState, action: programAnalysisFetchAction) => {
  let newState;
  switch (action.type) {
    case `${PROGRAM_ANALYSIS_SLICE}/${FETCH_FILTERS}/pending`:
      newState = { ...state, filters: { ...state.filters, fetchInfo: { ...state.filters.fetchInfo, loading: true } } };
      break;
    case `${PROGRAM_ANALYSIS_SLICE}/${FETCH_FILTERS}/rejected`:
      newState = { ...state, filters: { ...state.filters, fetchInfo: { ...state?.filters?.fetchInfo, loading: false, lastMessage: action.payload?.message as string || 'NO OK' } } };
      break;
    case `${PROGRAM_ANALYSIS_SLICE}/${FETCH_FILTERS}/fulfilled`:
      newState = {
        ...state,
        filters: {
          ...state.filters,
          ...(action.meta?.arg?.initial && state.filters.selected?.time_frame_year?.length === 0 ? { selected: (action.payload.data as Partial<filtersValues>) } : null),
          ...(!action.meta?.arg?.initial ? { selected: { ...state.filters.selected, ...(action.payload?.data as Partial<filtersValues>) } } : null),
          ...(action.meta?.arg?.initial ? { fetched: (action.payload.data as Partial<filtersValues>) } : null),
          fetchInfo: { ...state.filters.fetchInfo, loading: false, lastMessage: action.payload?.message as string },
        },
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const updateBuildMetadataReducer = (state: programAnalysisState, action: programAnalysisFetchAction) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  let newState = state;
  switch (action.type) {
    case `${PROGRAM_ANALYSIS_SLICE}/${FETCH_BUILD_METADATA}/pending`:
      newState = { ...state, build: { ...state.build, fetchInfo: { ...state.build.fetchInfo, loading: true } } };
      break;
    case `${PROGRAM_ANALYSIS_SLICE}/${FETCH_BUILD_METADATA}/rejected`:
      if (action.payload?.httpStatus && action.payload.httpStatus === 401) newState = { ...state, build: { ...state.build, status: POSSIBLE_BUILD_STATUS.UNAUTHORIZED, fetchInfo: { ...state.build.fetchInfo, loading: false, lastMessage: action.payload.message } } };
      else newState = { ...state, build: { ...state.build, status: 'UNKNOWN', fetchInfo: { ...state.build.fetchInfo, loading: false, lastMessage: action.payload?.message } } };
      break;
    case `${PROGRAM_ANALYSIS_SLICE}/${FETCH_BUILD_METADATA}/fulfilled`:
      // eslint-disable-next-line no-case-declarations
      const payloadData = action.payload.data as buildMetadata;
      // eslint-disable-next-line no-case-declarations, camelcase
      const { build_parameters, ...rest } = payloadData;
      // eslint-disable-next-line no-case-declarations, camelcase
      const { ac_program, ...filteredBuildParameters } = build_parameters;
      newState = {
        ...state,
        build: {
          ...rest,
          build_parameters: filteredBuildParameters,
          fetchInfo: { ...state.build.fetchInfo, loading: false },
        },
        filters: {
          ...state.filters,
          selected: {
            [TIME_FRAME_YEAR]: `${filteredBuildParameters[TIME_FRAME_YEAR]}Y`,
            ...(({
              [OPERATOR_ICAO_CODE]: _,
              [TIME_FRAME_YEAR]: _tfy,
              [MAINTENANCE_PROGRAM]: _mp,
              user_origin: _uo,
              ...filteredRest
            } = filteredBuildParameters) => (filteredRest))(),
          },
        },
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const updateFetchedTasksReducer = (state: programAnalysisState, action: programAnalysisFetchAction) => {
  let newState;
  switch (action.type) {
    case `${PROGRAM_ANALYSIS_SLICE}/${POST_FILTERS}/pending`:
      newState = {
        ...state,
        build: {
          ...state.build, [BUILD_STATUS]: '', [BUILD_ID]: '', fetchInfo: { ...state.build.fetchInfo, loading: true },
        },
      };
      break;
    case `${PROGRAM_ANALYSIS_SLICE}/${POST_FILTERS}/rejected`:
      newState = { ...state, build: { ...state.build, fetchInfo: { ...state.build.fetchInfo, loading: false, lastMessage: action.payload?.message } } };
      break;
    case `${PROGRAM_ANALYSIS_SLICE}/${POST_FILTERS}/fulfilled`:
      newState = {
        ...state,
        build: {
          ...state.build,
          [BUILD_ID]: action.payload.id,
          [BUILD_LIMIT_EXCEED]: action.payload.id === '',
          fetchInfo: {
            ...state.build.fetchInfo, loading: false, lastMessage: action.payload?.message,
          },
        },
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const updateFetchedTasksListReducer = (state: programAnalysisState, action: programAnalysisFetchAction) => {
  let newState;
  switch (action.type) {
    case `${PROGRAM_ANALYSIS_SLICE}/${FETCH_BUILD}/pending`:
      newState = { ...state, tasks: { ...state.tasks, fetchInfo: { ...state.tasks.fetchInfo, loading: true } } };
      break;
    case `${PROGRAM_ANALYSIS_SLICE}/${FETCH_BUILD}/rejected`:
      newState = { ...state, tasks: { ...state.tasks, fetchInfo: { ...state.tasks.fetchInfo, loading: false, lastMessage: action.payload?.message || ' Cold start' } }, build: { ...state.build, status: POSSIBLE_BUILD_STATUS.FAILED } };
      break;
    case `${PROGRAM_ANALYSIS_SLICE}/${FETCH_BUILD}/fulfilled`: {
      // Helper function to set opsfr , op_avg_yield to null if number_of_task_report is 0 or LSDR task && wwfr , fleet_yield to null for LSDR task
      const updatedTasks = Array.isArray(action.payload.data) ? (action.payload.data as maintenanceTask[])?.map((task) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const updatedTask = { ...task };

        if (task[NUMBER_OF_TASK_REPORT] === 0 || task[IS_CALCULABLE].includes(LSDR_TASK) || task[IS_CALCULABLE].includes(CSAF_SOURCE)) {
          updatedTask[OPERATOR_FINDING_RATE] = null;
          updatedTask[OP_AVG_YIELD] = null;
        }

        if (task[IS_CALCULABLE].includes(LSDR_TASK) || task[IS_CALCULABLE].includes(CSAF_SOURCE)) {
          updatedTask[WORLD_WIDE_FINDING_RATE] = null;
          updatedTask[FLEET_AVG_YIELD] = null;
        }

        return updatedTask;
      }) : (action.payload.data);
      // eslint-disable-next-line no-constant-condition
      newState = {
        ...state,
        performance: {
          ...state.performance,
          prevBuildId: state.build.id,
        },
        tasks: {
          ...state.tasks,
          data: updatedTasks as maintenanceTask[],
          fetchInfo: {
            ...state.tasks.fetchInfo,
            loading: false,
            lastMessage: action.payload.message,
          },
        },
      };
      break;
    }
    default: {
      newState = state;
      break;
    }
  }

  return newState;
};

/*
  END OF ASYNC REDUCERS (DATA FETCHED)
 */
