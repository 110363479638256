import { buildMetadata, filtersValues, programAnalysisState } from './programAnalysisTypes';

// Slice
export const PROGRAM_ANALYSIS_SLICE = 'programAnalysis';

// Action types
export const FETCH_FILTERS = 'fetchFilters';
export const FETCH_BUILD = 'fetchBuild';
export const POST_FILTERS = 'postFilters';
export const FETCH_BUILD_METADATA = 'fetchBuildMetadata';

// User Permission
export const ENRICH_TASK_OVERVIEW = 'ENRICH_TASK_OVERVIEW';
export const ENRICH_TASK_REPORT = 'ENRICH_TASK_REPORT';

/**
 * Field names retrieved when fetching data. Since these are mostly decided by Foundry
 * we need to map them and use the constants so it isn't a hassle to modify at will later on.
 */

export const MPD_TASK_REFERENCE = 'mpd_task_reference';
export const MPD_TASK_DESCRIPTION = 'mpd_task_description';
export const MPD_TASK_DISTRIBUTION = 'task_distribution';
export const MPD_TASK_CODE = 'mpd_task_code';
export const SOURCE_DOCUMENT_TYPE = 'source_document_type';
export const WORLD_WIDE_FINDING_RATE = 'world_wide_finding_rate';
export const OPERATOR_FINDING_RATE = 'operator_finding_rate';
export const OPERATOR_ENHANCED_FINDING_RATE = 'ffr';
export const NO_OF_PREIDENIFIED_OI = 'nb_pre_idntfd_ois_tada';
export const NO_OF_OIS_AT_FLEET_LEVEL = 'number_of_ois_at_fleet_level';
export const OP_AVG_YIELD = 'op_avg_yield';
export const FLEET_AVG_YIELD = 'fleet_avg_yield';
export const OPERATOR_FINDING_RATE_TADA = 'operator_finding_rate_tada';
export const NUMBER_OF_TASK_REPORT = 'number_of_task_report';
export const SUFFICIENT_TASK_REPORT = 'op_sufficient_tr';
export const OPPORTUNITY = 'opportunity';
export const INTERVAL_FH = 'interval_fh';
export const SCHEDULED_COST_DOLLAR = 'scheduled_cost_dollar';
export const UNSCHEDULED_COST_DOLLAR = 'unscheduled_cost_dollar';
export const SCHEDULED_COST_PERCENT = 'scheduled_cost_percent';
export const UNSCHEDULED_COST_PERCENT = 'unscheduled_cost_percent';
export const IS_CALCULABLE = 'is_calculable';

export const COST_AIRCRAFT_YEAR = 'cost_aircraft_year';
export const OPERATOR_YIELD = 'op_avg_yield';
export const MAX_COST_AIRCRAFT_YEAR = 'max_cost_aircraft_year';

export const OPERATOR_ICAO_CODE = 'operator_icao_code';
export const MAINTENANCE_PROGRAM = 'maintenance_program';
export const AC_TYPE = 'ac_type';
export const AC_SERIES = 'ac_series';
export const ID_AIRCRAFT = 'id_aircraft';
export const TIME_FRAME_YEAR = 'time_frame_year';
export const ENGINE_SERIES = 'engine_series';
export const ATA_2D = 'ata_2d';
export const ATA_4D = 'ata_4d';

export const BUILD_ID = 'id';
export const BUILD_UPDATE_TIME = 'update_time';
export const BUILD_VERSION = 'version';
export const BUILD_CREATION_TIME = 'creation_time';
export const BUILD_TYPE = 'build_type';
export const BUILD_CREATED_BY = 'created_by';
export const BUILD_ERRORS = 'errors';
export const BUILD_STATUS = 'status';
export const BUILD_DATASETS = 'datasets';
export const BUILD_LIMIT_EXCEED = 'buildLimitExceed';
export const BUILD_PROGRESS_PER = 'progres_percentage';
export const DATASET_CREATION_TIME = 'creation_time';
export const DATASET_UPDATE_TIME = 'update_time';
export const DATASET_ERRORS = 'errors';
export const DATASET_STATUS = 'status';
export const IMPACTED_BY_ANNEX12 = 'is_annex13_impacted';

export const POSSIBLE_BUILD_STATUS = {
  READY: 'READY',
  IN_PROGRESS: 'IN_PROGRESS',
  INIT: 'INIT',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FAILED: 'FAILED',
  UPDATE_INIT: 'UPDATE_INIT',
};

export const lsdrTasks = ['LUB', 'SVC', 'DIS', 'RST'];
export const LSDR_TASK = 'LSDR_TASK';
export const CSAF_SOURCE = 'CSAF_source';
/**
 * End of field names
 */

// API paths
export const GET_FILTERS_ENDPOINT = 'api/csm/filters';
// This is the end point to fetch the filter drop down on Program analysis page
export const GET_FILTERS_PRECOMP_ENDPOINT = 'api/precompute/filters';
export const POST_FILTERS_ENDPOINT = 'api/smpa/scopes';
// Precompute create filter api path
export const POST_FILTERS_PRECOMP_ENDPOINT = 'api/precompute/scopes';

export const GET_TASKS_ENDPOINT = (buildId: string) => (`api/smpa/scopes/${buildId}/datasets/csm_mpd_filtered`);
// Precompute get task distribution api path
export const GET_TASKS_PRECOMP_ENDPOINT = (buildId: string) => (`api/precompute/scopes/${buildId}/data/read-td-data`);
export const GET_BUILD_METADATA_ENDPOINT = (buildId: string) => (`api/smpa/scopes/${buildId}`);
export const GET_BUILD_METADATA_ENDPOINT_PRECOMP = (buildId: string) => (`api/precompute/scopes/${buildId}`);

export const filtersInitialState: filtersValues = {
  [OPERATOR_ICAO_CODE]: '',
  [MAINTENANCE_PROGRAM]: '',
  [AC_TYPE]: [],
  [AC_SERIES]: [],
  [ID_AIRCRAFT]: [],
  [TIME_FRAME_YEAR]: '',
  [ENGINE_SERIES]: [],
  [ATA_2D]: [],
  [ATA_4D]: [],
};

export const buildInitialState: buildMetadata = {
  [BUILD_ID]: '',
  [BUILD_TYPE]: '',
  [BUILD_STATUS]: '',
  [BUILD_VERSION]: 0,
  [BUILD_CREATION_TIME]: undefined,
  [BUILD_UPDATE_TIME]: undefined,
  [BUILD_CREATED_BY]: '',
  [BUILD_ERRORS]: [],
  [BUILD_DATASETS]: {},
  [BUILD_LIMIT_EXCEED]: false,
  fetchInfo: {
    loading: false,
    lastMessage: '',
  },
};

export const initialState: programAnalysisState = {
  filters: {
    selected: filtersInitialState,
    fetched: filtersInitialState,
    difference: filtersInitialState,
    fetchInfo: {
      loading: false,
      lastMessage: '',
    },
  },
  // to store prev build id to avoid cache issue
  performance: {
    prevBuildId: '',
  },
  tasks: {
    data: [],
    filteredData: undefined,
    selectedTaskReference: undefined,
    currentPage: 1,
    sort: [{
      id: MPD_TASK_REFERENCE,
      desc: false,
    }],
    filters: {},
    fetchInfo: {
      loading: false,
      lastMessage: '',
    },
  },
  build: buildInitialState,
};
