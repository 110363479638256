import React, {
  useState, useEffect, ReactElement,
} from 'react';
import {
  DataTable, Button, Container, Row, Col, Inline, Typography, Chip, Pagination,
} from '@airbus/components-react';
import {
  Reset,
} from '@airbus/icons/react';
import mpdConfig from '../../__mocks__/MpdCustomization/mpdConfig.json';
import './MpdTaskTable.scss';
import {
  generateTableColumns, generateConfigTableColumns, generateHidableColumns, getTableColumns, TableColumnState,
  formateTableColumn,
} from './mpdTableStructureUtils';
import { fileURLReturnType, extractGeneralInfoData } from './mpdTableUtils';
import SMPCTableModal from './components/SMPCTableModal/SMPCTableModal';
import SMPCTableControls from './components/SMPCTableControls/SMPCTableControls';
import SMPCTableHiddenCols from './components/SMPCTableHiddenCols/SMPCTableHiddenCols';
import {
  deleteOneEmtlTableFilter,
  clearAllEmtlTableFilter,
  updateEmtlTablePageNumber,
  updateEmtlExportButtonState,
} from '../../models/mpdEmtlTableModel/mpdEmtlTableSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState, store } from '../../store/store';
import { createMpdEmtlTableExportTask, createMpdTable, getFileURL } from '../../models/mpdEmtlTableModel/mpdEmtlTableAsyncThunk';
import { addBanner, clearBannerLocation, removeBanner } from '../../models/bannerModel/bannerSlice';
import {
  bannerLocationMap,
  EXPORT_FAILED,
  EXPORT_IN_PROGRESS,
  EXPORT_SUCCESS,
  NO_DATA_FOUND,
  PROG_CUST_EXPORT_DOWNLOAD_IN_PROGRESS,
  PROG_CUST_EXPORT_DOWNLOAD_SUCCESS,
  PROG_CUST_EXPORT_DOWNLOAD_FAILED,
  PROG_CUST_EXPORT_DOWNLOAD_TIMEOUT,
  PROG_CUST_EXPORT_DOWNLOAD_ERROR,
} from '../../models/bannerModel/constants';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';
import { startPolling } from '../../utils/APIPollingUtil/APIPolling';
import { updateEmtlPageNumber } from '../../models/mpdRepositoryModel/mpdRepositorySlice';
import { createRepositoryTaskList } from '../../models/mpdRepositoryModel/mpdRepositoryAsyncThunk';
import { EMTL_TABLE_EXPORT_STATUS_POLLING_API_PATH } from '../../utils/APIPollingUtil/constants';
import { PollRequest, pollResponse } from '../../utils/APIPollingUtil/APIPollingTypes';
import { downloadFile } from '../../container/MpdRepository/mpdRepositoryUtils';
import { useUserPermission } from '../../utils/customHooks/useUserPermission';

let emtlTableCol: emtlColType[] = [];
const mpdNotSerchable = ['effectivity_calculated', 'effectivity_adjusted', 'missing_sb_report'];
const MpdTaskTable = function MpdTaskTable() {
  const dispatch = useAppDispatch();
  const {
    EmtlTableDataRowFilteredDataType: emtlTableDataRowFiltered,
    loading: tableLoading,
    EmtlTableFilterGroup: emtlfilterGroup,
    EmtlTableDataRowCountType: totalRecordsCount,
    EmtlTableDataPageNumberType: currentPage,
    EmtlTableExportButton: exportBtnState,
    EmtlTableColumnType: emtlSelectedCol,
    EmtlTableHeaders: columnHeaders,
  } = useAppSelector((state: RootState) => state.emtlTable);
  const {
    AircraftMsnRnoSearchType: acMsnRegNo,
    AircraftSeriesType: acSeries,
  } = useAppSelector((state: RootState) => state.mpdDrawer);
  const {
    EffectiveMaintenenceTaskListEstablishedFrom: emtlEstablishedFrom,
    EffectiveMaintenenceTaskListIssueData: emtlIssueData,
  } = useAppSelector((state: RootState) => state.effectiveMaintenenceTaskList);
  const {
    RepositoryTaskListRowsPerPage: repoEmtlRowsPerPage,
    RepositoryFilterGroup: filterGroup,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  const acProgram = secureLS.get('ac_program');
  const [modalOpen] = React.useState(false);
  const [hiddenColsShown, setHiddenColsShown] = React.useState(false);
  const [hiddenColumn, sethiddenColumn] = useState<string>('');
  const [hidableTableColumns, setHidableTableColumns] = useState<Array<hidableMpdTask>>([]);
  const [configTableColumns] = useState<mpdTableColsType[]>(generateConfigTableColumns());
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const isExportAllowed = useUserPermission(['EMTL_EXPORT']);

  // Show banner when no data available for selected filters and hides otherwise
  useEffect(() => {
    emtlTableCol = formateTableColumn(columnHeaders.EMTL);
    setHidableTableColumns(generateHidableColumns(generateTableColumns(sethiddenColumn, emtlTableCol)));
  }, [columnHeaders]);

  useEffect(() => {
    if (tableLoading === false && emtlTableDataRowFiltered.length === 0) {
      dispatch(addBanner(NO_DATA_FOUND));
    } else {
      dispatch(removeBanner(NO_DATA_FOUND));
    }
  }, [emtlTableDataRowFiltered, tableLoading, dispatch]);

  useEffect(() => {
    setHidableTableColumns((prevState) => prevState.map((hideCol) => ({
      ...hideCol,
      shown: hideCol.shown && !(hideCol.column.id === hiddenColumn),
    })));
  }, [hiddenColumn]);

  useEffect(() => {
    setHidableTableColumns((prevState) => (prevState.map((hideCol) => {
      const header = React.cloneElement(hideCol.column.Header, { hideMode: hiddenColsShown });
      return {
        column: { ...hideCol.column, Header: header, disableSortBy: hiddenColsShown },
        shown: hideCol.shown,
      };
    })));
  }, [hiddenColsShown]);

  useEffect(() => {
    dispatch(updateEmtlTablePageNumber(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emtlfilterGroup]);

  useEffect(() => {
    dispatch(createMpdTable('EMTL', acMsnRegNo, emtlfilterGroup, rowsPerPage, currentPage)())
      .unwrap().then((results: CreateMpdEmtlTableFilterResponse) => {
        if (results.totalHits === 0) {
          dispatch(clearBannerLocation(bannerLocationMap.PROGRAM_CUSTOMISATION));
          dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
          dispatch(addBanner(NO_DATA_FOUND));
        } else {
          dispatch(removeBanner(NO_DATA_FOUND));
        }
      }).catch(() => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emtlfilterGroup, currentPage, rowsPerPage]);

  const clearHiddenCol = (id: string) => {
    setHidableTableColumns([...hidableTableColumns].map((hideCol) => ({
      ...hideCol,
      shown: hideCol.column.id === id ? true : hideCol.shown,
    })));
    sethiddenColumn('');
  };

  const clearAllHiddenCols = () => {
    setHidableTableColumns([...hidableTableColumns].map((hideCol) => ({
      column: hideCol.column,
      shown: true,
    })));
    sethiddenColumn('');
  };

  const renderTableControls = () => {
    return (
      <SMPCTableControls
        searchColumns={emtlTableCol.filter((item) => !mpdNotSerchable.includes(item.id))}
        showHiddenCols={setHiddenColsShown}
      />
    );
  };

  const clearBanners = () => {
    dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
    dispatch(clearBannerLocation(bannerLocationMap.PROGRAM_CUSTOMISATION));
  };

  const emtlExportPollingPayload = (fileId: string) => {
    const response: PollRequest = {
      data: {
        url: `${EMTL_TABLE_EXPORT_STATUS_POLLING_API_PATH}?export-task-id=${fileId}`,
        interval: 5500,
        retriesAllowed: 5,
        successStatus: ['DONE'],
        failureStatus: ['FAIL', 'DELETE'],
      },
      onSuccess: () => {
        clearBanners();
        dispatch(addBanner(EXPORT_SUCCESS));
        dispatch(updateEmtlExportButtonState(false));
        const tab = store.getState().repositoryTaskList.RepositoryEMTLTabType;

        /* istanbul ignore next */
        if (window.location.pathname === '/repository/emtl' && store.getState().repositoryTaskList.RepositoryEMTLTabType === 'EMTL') {
          dispatch(updateEmtlPageNumber(1));
          dispatch(createRepositoryTaskList(repoEmtlRowsPerPage, 1, acProgram, filterGroup, tab)());
        }
      },
      onFailure: (resp: pollResponse | null, error?: unknown) => {
        clearBanners();
        dispatch(updateEmtlExportButtonState(false));
        if (resp) {
          const bannerMessage = EXPORT_FAILED.bannerMessage.replace('`<FILENAME>`', `"${resp.data.fileName}"`);
          dispatch(addBanner({
            ...EXPORT_FAILED,
            bannerMessage,
          }));
        } else {
          dispatch(addBanner({
            ...EXPORT_FAILED,
            bannerMessage: `${JSON.stringify(error)}: please check the repository in sometime.`,
          }));
        }
      },
      onTimeout: () => {
        clearBanners();
        dispatch(addBanner({
          ...EXPORT_FAILED,
          bannerMessage: 'Export is taking longer than expected, please check the repository in sometime.',
        }));
        dispatch(updateEmtlExportButtonState(false));
      },
    };
    return response;
  };

  const emtlDownloadPollingPayload = (fileId: string) => {
    const response: PollRequest = {
      data: {
        url: `${EMTL_TABLE_EXPORT_STATUS_POLLING_API_PATH}?export-task-id=${fileId}`,
        interval: 5500,
        retriesAllowed: 10,
        successStatus: ['DONE'],
        failureStatus: ['FAIL', 'DELETE'],
      },
      onSuccess: async (resp: pollResponse) => {
        try {
          const result: fileURLReturnType = await dispatch(getFileURL(resp.data.taskId, 'xlsx')()).unwrap();
          downloadFile(result.data.downloadUrl);
          clearBanners();
          dispatch(addBanner(PROG_CUST_EXPORT_DOWNLOAD_SUCCESS));
        } catch (error) {
          response.onFailure(resp, null);
        } finally {
          dispatch(updateEmtlExportButtonState(false));
        }
      },
      onFailure: (resp: pollResponse | null, error?: unknown) => {
        clearBanners();
        dispatch(updateEmtlExportButtonState(false));
        if (resp) {
          dispatch(addBanner({
            ...PROG_CUST_EXPORT_DOWNLOAD_FAILED,
            bannerMessage: PROG_CUST_EXPORT_DOWNLOAD_FAILED.bannerMessage.replace('`<FILENAME>`', `"${resp.data.fileName}"`),
          }));
        } else {
          dispatch(addBanner({
            ...PROG_CUST_EXPORT_DOWNLOAD_ERROR,
            bannerMessage: PROG_CUST_EXPORT_DOWNLOAD_ERROR.bannerMessage.replace('<ERROR>', `${JSON.stringify(error)}`),
          }));
        }
      },
      onTimeout: () => {
        clearBanners();
        dispatch(addBanner(PROG_CUST_EXPORT_DOWNLOAD_TIMEOUT));
        dispatch(updateEmtlExportButtonState(false));
      },
    };
    return response;
  };

  const fileExportHandler = async () => {
    const inProgressBanner = isExportAllowed ? EXPORT_IN_PROGRESS : PROG_CUST_EXPORT_DOWNLOAD_IN_PROGRESS;
    const failedBanner = isExportAllowed ? EXPORT_FAILED : PROG_CUST_EXPORT_DOWNLOAD_FAILED;
    const pollingPayload = isExportAllowed ? emtlExportPollingPayload : emtlDownloadPollingPayload;

    dispatch(updateEmtlExportButtonState(true));
    clearBanners();
    dispatch(addBanner(inProgressBanner));
    const generalInfo = extractGeneralInfoData(emtlEstablishedFrom, emtlIssueData);

    try {
      const response = await dispatch(createMpdEmtlTableExportTask(
        acProgram,
        'EMTL',
        acSeries,
        acMsnRegNo,
        generalInfo.issueNumber,
        generalInfo.issueDate,
        generalInfo.mpdRevision,
        generalInfo.revisionDate,
        generalInfo.mpdIssueNumber,
        generalInfo.mpdIssueDate,
      )()).unwrap();

      dispatch(startPolling(pollingPayload(response.data.taskId)));
    } catch (error) {
      clearBanners();
      dispatch(addBanner({
        ...failedBanner,
        bannerMessage: failedBanner.bannerMessage.replace('`<FILENAME>` ', ''),
      }));
      dispatch(updateEmtlExportButtonState(false));
    }
  };

  const renderHiddenColsSection = () => (
    <SMPCTableHiddenCols
      showHiddenCols={setHiddenColsShown}
      hiddenCols={getTableColumns(hidableTableColumns, TableColumnState.Hidden)}
      clearAll={clearAllHiddenCols}
      clearHiddenCol={clearHiddenCol}
      hiddenColsShown={hiddenColsShown}
    />
  );

  const displayChips = () => {
    const chipsArr: ReactElement[] = [];
    emtlfilterGroup.forEach((element, idx) => {
      const emtlColLabel = emtlTableCol.find((mpdCol) => mpdCol.id === element.filter_column);
      chipsArr.push(
        <Chip
          className="table-filter-chip"
          title={`${emtlColLabel?.label}: ${element.filter_value}`}
          label={`${emtlColLabel?.label}: ${element.filter_value}`}
          onDelete={() => {
            dispatch(deleteOneEmtlTableFilter([{ filter_column: emtlfilterGroup[idx].filter_column, filter_value: element.filter_value }]));
          }}
        />,
      );
    });
    return chipsArr;
  };

  return (
    <Container className="mpd-task-table-cls custom-container">
      <>
        <Row alignItems="center">
          <Col xxs={12} md={4}>
            <Typography variant="large"><b>Effective Maintenance Task List</b></Typography>
          </Col>
          <Col xxs={12} md={8}>
            {!hiddenColsShown && renderTableControls()}
          </Col>
        </Row>
        <Row alignItems="center">
          <Col xxs={12}>
            {(hiddenColsShown || getTableColumns(hidableTableColumns, TableColumnState.Hidden).length > 0) && renderHiddenColsSection()}
          </Col>
        </Row>
        {emtlfilterGroup.length > 0 ? (
          <Row alignItems="center" className="mpd-emtl-table-filter-group-cls" aria-label="mpd-selected-filters">
            <Col xxs={12} md={12}>
              <Inline spacing="2-x" wrap>
                <Typography variant="small" className="bold-title">Filter</Typography>
                <Inline spacing="1-x" wrap>
                  {displayChips()}
                </Inline>
                <Button
                  variant="ghost"
                  size="small"
                  aria-label="mpdEmtlTableFilterClearAll"
                  onClick={() => {
                    dispatch(clearAllEmtlTableFilter(emtlSelectedCol));
                  }}
                >
                  <div className="clear-all-btn-icon">
                    <Reset />
                  </div>
                  Clear All
                </Button>
              </Inline>
            </Col>
          </Row>
        ) : null}
        <DataTable
          className="margin-table"
          height={500}
          columns={getTableColumns(hidableTableColumns, TableColumnState.Shown)}
          data={emtlTableDataRowFiltered}
          defaultRowsPerPage={rowsPerPage}
          aria-label="maintenance-tasks-table"
          loading={tableLoading}
        />
        <Pagination
          aria-label="mpd-pagination"
          legend={`Showing ${totalRecordsCount === 0 ? 0 : ((currentPage - 1) * rowsPerPage) + 1}-${Math.min(currentPage * rowsPerPage, totalRecordsCount)} of ${totalRecordsCount} results`}
          page={currentPage}
          count={totalRecordsCount}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={(event, num) => {
            dispatch(updateEmtlTablePageNumber(num));
          }}
          onRowsPerPageChange={(e, val) => {
            setRowsPerPage(val);
            dispatch(updateEmtlTablePageNumber(1));
          }}
          showGoto
        />
        <Inline spacing="1-x">
          <Button
            variant="primary"
            className="button extra-margin-top"
            aria-label="mpdEmtlTableExportRepository"
            onClick={fileExportHandler}
            disabled={exportBtnState}
          >
            {isExportAllowed ? 'Export Task List' : 'Download Task List'}
          </Button>
        </Inline>
      </>
      <SMPCTableModal
        modalOpen={modalOpen}
        columns={configTableColumns}
        data={mpdConfig}
      />
    </Container>
  );
};

export default MpdTaskTable;
