/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, useMemo, useContext,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Toggle } from '@airbus/components-react';
import { operatorInfoArray } from '../../__mocks__/TaskReportAnalysis/operatorData';
import { fleetInfoArray } from '../../__mocks__/TaskReportAnalysis/fleetData';
import CardComponent from '../Shared/Card/CardComponent';
import ChartComponent, { type ChartDropDown } from '../TACharts/TAChartComponent';
import ScrollToTopButton from '../../utils/commonUtils/scrollTopBtn/ScrollTopBtn';
import {
  tabsActualInterval, tabsCumulativeInterval,
} from '../../__mocks__/TaskReportAnalysis/ChartTabs';
import SpinnerComponent from '../Spinner/SpinnerComponent';
import { LOCALES } from '../../assets/locale';
import QuickLinks from '../Shared/QuickLinks/QuickLinks';
import { taskReportQLinks } from '../../__mocks__/TaskReportAnalysis/QuickLinks';
import RangeChart from '../Shared/RangeChart/RangeChart';
import TaskReportTable from './TATables/TaskReportTable';
import {
  fetchTaskInfoFleetLevelGeoRegionChart,
  fetchTaskInfoFleetLevelFindingChart,
  fetchTaskInfoOperatorLevelFindingChart,
  fetchTaskInfoOperatorLevelReportReceivedYearly,
  fetchTaskInfoOperatorLevelFindingRateByMsn,
  fetchPrecompIntervalByType,
  fetchTaskReportOperatorCard,
  fetchTaskReportWorldWideFleetCard,
  fetchTaskInfoOperatorLevelFindingRateByMsnForTada,
  fetchTaskInfoFleetLevelGeoRegionChartForTada,
  fetchTaskInfoOperatorLevelFindingChartForTada,
  fetchTaskInfoFleetLevelFindingChartForTada,
  fetchTaskReportWorldWideFleetCardForTada,
  fetchTaskReportOperatorCardForTada,
} from '../../models/taskReportModel/taskreportAsyncThunks';
import {
  getFindingRateByClimateAreasChartData, getFleetWideFindingsAndTypeChartData,
  getOperatorReportsPerYearChartData, getOperatorFindingsAndTypeChartData,
  getTaskReportAnalysisCardBody, getWorldWideFeetCardBody,
  getTaskReportsByActualIntervalChartData, getTaskReportsByCummulativeIntervalChartData,
  getTaskReportAndFindingRateByMSNChartData,
  getDropDownData, getFilteredChartData, updateActualIntervalInStore,
} from '../../models/taskReportModel/taskReportUtils';
import { OPERATOR_PARAM } from '../../container/TaskReport/constants';
import {
  precompActualIntervalByTypeFulfilled, precompActualIntervalByTypeInit, precompActualIntervalByTypeRejected, updateTaskReportPerformanceTuning,
  precompCumulativeIntervalByTypeInit, precompCumulativeIntervalByTypeFulfilled, precompCumulativeIntervalByTypeRejected,
  updateEnhancedAnalysisTadaToggle,
  precompActualIntervalByTypeTadaInit,
  precompCumulativeIntervalByTypeTadaInit,
  precompActualIntervalByTypeTadaRejected,
  precompCumulativeIntervalByTypeTadaRejected,
  precompActualIntervalByTypeTadaFulfilled,
  precompCumulativeIntervalByTypeTadaFulfilled,
} from '../../models/taskReportModel/taskReportSlice';

import { RootState } from '../../store/store';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import {
  CUMMULATIVE_INTERVAL_AGE,
  CUMMULATIVE_INTERVAL_FC,
  CUMMULATIVE_INTERVAL_FH,
  INTERVAL_CHART_DROPDOWN_TITLE, INTERVAL_FC, INTERVAL_FH, INTERVAL_MONTH,
  DEFAULT_TABLE_DATA,
  INTERVAL_INFO_OPSFR,
  INTERVAL_INFO_WWFR,
  INTERVAL_INFO_FD,
} from './constants';
import { PROGRAM_ANALYSIS_MODULE, PREMIUM_PERMISSION, MPE_PERMISSION } from '../../utils/commonUtils/PermissionMapper';

import { getTabIndexBasedOnDataAvailability } from './TRAnalysisUtils';
import SkywiseCrown from '../Shared/SkywiseCrown/SkywiseCrown';
import TooltipComponent from '../Shared/MpdTooltip/mpdTooltip';
import { featureToggleConfig } from '../../utils/FeatureToggleUtil/FeatureToggleUtil';
import { appContext } from '../../utils/context/userContext';
import { checkPermissionByType } from '../../utils/userPermissionUtils/userPermissionUtils';

import './TaskReportAnalysis.scss';

interface FilterDropdownValues {
  type: string;
  data: Array<string>;
}

export default function TaskReportAnalysis() {
  const dispatch = useAppDispatch();
  const { filterId, taskNumber } = useParams();
  const [searchParams] = useSearchParams();
  const { userType } = useContext(appContext);
  const operator = searchParams.get(OPERATOR_PARAM);

  const taskInfoStatus = useAppSelector((state: RootState) => state.task.httpStatus);
  const isCalculable = useAppSelector((state: RootState) => state.task.taskInfo.is_calculable);
  const isTadaOn = useAppSelector((state: RootState) => state.task.tada.isTadaOn);
  const findingRateByGeoRegion = useAppSelector((state: RootState) => (isTadaOn ? state.task.tada.findingRateByGeoRegion : state.task.findingRateByGeoRegion));
  const fleetWideFindingsByFindingType = useAppSelector((state: RootState) => (isTadaOn ? state.task.tada.fleetWideFindingsByFindingType : state.task.fleetWideFindingsByFindingType));
  const taskReportAnalysisOperatorCard = useAppSelector((state: RootState) => (isTadaOn ? state.task.tada.taskReportAnalysisOperatorCard : state.task.taskReportAnalysisOperatorCard));
  const taskInfoFleetCard = useAppSelector((state: RootState) => (isTadaOn ? state.task.tada.taskInfoFleetCard : state.task.taskInfoFleetCard));
  const taskInfoOperatorLevelReportReceivedYearly = useAppSelector((state: RootState) => state.task.taskInfoOperatorLevelReportReceivedYearly);
  const taskInfoOperatorLevelFindingChart = useAppSelector((state: RootState) => (isTadaOn ? state.task.tada.taskInfoOperatorLevelFindingChart : state.task.taskInfoOperatorLevelFindingChart));
  const taskInfoOperatorLevelFindingRateByMsn = useAppSelector((state: RootState) => (isTadaOn ? state.task.tada.taskInfoOperatorLevelFindingRateByMsn : state.task.taskInfoOperatorLevelFindingRateByMsn));
  const { data: taskReportByActualInterval, status: actualIntervalStatus, loading: actualIntervalLoading } = useAppSelector((state: RootState) => (isTadaOn ? state.task.tada.taskReportByActualInterval : state.task.taskReportByActualInterval));
  const { data: taskReportByCummulativeInterval, status: cummulativeIntervalStatus, loading: cummulativeIntervalLoading } = useAppSelector((state: RootState) => (isTadaOn ? state.task.tada.taskReportByCummulativeInterval : state.task.taskReportByCummulativeInterval));
  const performance = useAppSelector((state: RootState) => state.task.performance);
  const performanceTada = useAppSelector((state: RootState) => state.task.tada.performance);
  const [filteredIntervalDropdownValues, setFilteredIntervalDropdownValues] = useState<FilterDropdownValues>({ type: '', data: [] });
  const [filteredCummulativeDropdownValues, setFilteredCummulativeDropdownValues] = useState<FilterDropdownValues>({ type: '', data: [] });
  const [initRender, setInitRender] = useState(true);

  const filteredTaskReportByActualInterval = useMemo(() => {
    const { type, data } = filteredIntervalDropdownValues;
    return getFilteredChartData(taskReportByActualInterval, type, data);
  }, [taskReportByActualInterval, filteredIntervalDropdownValues]);

  const filteredTaskReportByCummulativeInterval = useMemo(() => {
    const { type, data } = filteredCummulativeDropdownValues;
    return getFilteredChartData(taskReportByCummulativeInterval, type, data);
  }, [taskReportByCummulativeInterval, filteredCummulativeDropdownValues]);

  const getOnlyLabel = (e: ChartDropDown[]) => {
    return e.map((item: ChartDropDown) => item.label);
  };

  const getGraphData = (graph: string, arr: TaskReportsByActualIntervalData[]) => {
    return arr.filter((item) => item.graph === graph)?.[0];
  };
  const getGraphTitle = (chartTitle: string, tadaOn: boolean) => {
    return (
      <b>
        {chartTitle}
        {tadaOn && <SkywiseCrown />}
      </b>
    );
  };

  const onActualIntervalDropwdownChange = (type: string, e: ChartDropDown[]) => {
    setFilteredIntervalDropdownValues({ type, data: getOnlyLabel(e) });
  };

  const onCummulativeIntervalDropwdownChange = (type: string, e: ChartDropDown[]) => {
    setFilteredCummulativeDropdownValues({ type, data: getOnlyLabel(e) });
  };

  /** Cards */
  const operatorCardBody = useMemo(() => getTaskReportAnalysisCardBody(taskReportAnalysisOperatorCard, isTadaOn), [taskReportAnalysisOperatorCard]);
  const wwfCardBody = useMemo(() => getWorldWideFeetCardBody(taskInfoFleetCard, isTadaOn), [taskInfoFleetCard]);
  /** Charts */
  const findingRateByClimateAreasChartData = useMemo(() => getFindingRateByClimateAreasChartData(findingRateByGeoRegion), [findingRateByGeoRegion]);
  const fleetWideFindingsAndTypeChartData = useMemo(() => getFleetWideFindingsAndTypeChartData(fleetWideFindingsByFindingType), [fleetWideFindingsByFindingType]);
  const operatorReportsPerYearChartData = useMemo(() => getOperatorReportsPerYearChartData(taskInfoOperatorLevelReportReceivedYearly), [taskInfoOperatorLevelReportReceivedYearly]);
  const operatorFindingsAndTypeChartData = useMemo(() => getOperatorFindingsAndTypeChartData(taskInfoOperatorLevelFindingChart), [taskInfoOperatorLevelFindingChart]);
  /** Range Chart */
  const taskReportAndFindingRateByMSNChartData = useMemo(() => getTaskReportAndFindingRateByMSNChartData(taskInfoOperatorLevelFindingRateByMsn), [taskInfoOperatorLevelFindingRateByMsn]);
  /** Interval Graph */
  const taskReportByActualIntervalFHChartData = useMemo(() => getTaskReportsByActualIntervalChartData(getGraphData('FH', filteredTaskReportByActualInterval)), [filteredTaskReportByActualInterval]);
  const taskReportByActualIntervalFCChartData = useMemo(() => getTaskReportsByActualIntervalChartData(getGraphData('FC', filteredTaskReportByActualInterval)), [filteredTaskReportByActualInterval]);
  const taskReportByActualIntervalMonthChartData = useMemo(() => getTaskReportsByActualIntervalChartData(getGraphData('MONTH', filteredTaskReportByActualInterval)), [filteredTaskReportByActualInterval]);
  /** Cummulative Interval Graph */
  const taskReportByCummulativeIntervalFHChartData = useMemo(() => getTaskReportsByCummulativeIntervalChartData(getGraphData('CUMULATIVE_FH', filteredTaskReportByCummulativeInterval)), [filteredTaskReportByCummulativeInterval]);
  const taskReportByCummulativeIntervalFCChartData = useMemo(() => getTaskReportsByCummulativeIntervalChartData(getGraphData('CUMULATIVE_FC', filteredTaskReportByCummulativeInterval)), [filteredTaskReportByCummulativeInterval]);
  const taskReportByCummulativeIntervalAgeChartData = useMemo(() => getTaskReportsByCummulativeIntervalChartData(getGraphData('AGE', filteredTaskReportByCummulativeInterval)), [filteredTaskReportByCummulativeInterval]);

  const taskReportByActualIntervalFHDropDownData = useMemo(
    () => {
      return {
        key: `${new Date().getTime()}-${INTERVAL_FH}`,
        type: INTERVAL_FH,
        data: getDropDownData(getGraphData(INTERVAL_FH, taskReportByActualInterval)),
        titleText: INTERVAL_CHART_DROPDOWN_TITLE,
        onChangeCallbackFunc: onActualIntervalDropwdownChange,
      };
    },
    [taskReportByActualInterval],
  );
  const taskReportByActualIntervalFCDropDownData = useMemo(() => {
    return {
      key: `${new Date().getTime()}-${INTERVAL_FC}`,
      type: INTERVAL_FC,
      data: getDropDownData(getGraphData(INTERVAL_FC, taskReportByActualInterval)),
      titleText: INTERVAL_CHART_DROPDOWN_TITLE,
      onChangeCallbackFunc: onActualIntervalDropwdownChange,
    };
  }, [taskReportByActualInterval]);
  const taskReportByActualIntervalMonthDropDownData = useMemo(() => {
    return {
      key: `${new Date().getTime()}-${INTERVAL_MONTH}`,
      type: INTERVAL_MONTH,
      data: getDropDownData(getGraphData(INTERVAL_MONTH, taskReportByActualInterval)),
      titleText: INTERVAL_CHART_DROPDOWN_TITLE,
      onChangeCallbackFunc: onActualIntervalDropwdownChange,
    };
  }, [taskReportByActualInterval]);
  const taskReportByCummulativeIntervalFHDropDownData = useMemo(() => {
    return {
      key: `${new Date().getTime()}-${CUMMULATIVE_INTERVAL_FH}`,
      type: CUMMULATIVE_INTERVAL_FH,
      data: getDropDownData(getGraphData(CUMMULATIVE_INTERVAL_FH, taskReportByCummulativeInterval)),
      titleText: INTERVAL_CHART_DROPDOWN_TITLE,
      onChangeCallbackFunc: onCummulativeIntervalDropwdownChange,
    };
  }, [taskReportByCummulativeInterval]);
  const taskReportByCummulativeIntervalFCDropDownData = useMemo(() => {
    return {
      key: `${new Date().getTime()}-${CUMMULATIVE_INTERVAL_FC}`,
      type: CUMMULATIVE_INTERVAL_FC,
      data: getDropDownData(getGraphData(CUMMULATIVE_INTERVAL_FC, taskReportByCummulativeInterval)),
      titleText: INTERVAL_CHART_DROPDOWN_TITLE,
      onChangeCallbackFunc: onCummulativeIntervalDropwdownChange,
    };
  }, [taskReportByCummulativeInterval]);
  const taskReportByCummulativeIntervalAgeDropDownData = useMemo(() => {
    return {
      key: `${new Date().getTime()}-${CUMMULATIVE_INTERVAL_AGE}`,
      type: CUMMULATIVE_INTERVAL_AGE,
      data: getDropDownData(getGraphData(CUMMULATIVE_INTERVAL_AGE, taskReportByCummulativeInterval)),
      titleText: INTERVAL_CHART_DROPDOWN_TITLE,
      onChangeCallbackFunc: onCummulativeIntervalDropwdownChange,
    };
  }, [taskReportByCummulativeInterval]);

  /** Get the index of actual interval tabs based on the availability of the data */
  const actualIntervalDataIndex = useMemo(() => {
    return getTabIndexBasedOnDataAvailability([taskReportByActualIntervalFHChartData, taskReportByActualIntervalFCChartData, taskReportByActualIntervalMonthChartData]);
  }, [taskReportByActualIntervalFHChartData, taskReportByActualIntervalFCChartData, taskReportByActualIntervalMonthChartData]);

  /** Get the index of cummulative interval tabs based on the availability of the data */
  const cummulativeIntervalDataIndex = useMemo(() => {
    return getTabIndexBasedOnDataAvailability(
      [taskReportByCummulativeIntervalAgeChartData, taskReportByCummulativeIntervalFHChartData, taskReportByCummulativeIntervalFCChartData],
    );
  }, [taskReportByActualIntervalFHChartData, taskReportByActualIntervalFCChartData, taskReportByActualIntervalMonthChartData]);

  const fetchAllDataNonTada = async () => {
    /** API - fetch task Report World Wide Fleet Card without Tada */
    dispatch(fetchTaskReportWorldWideFleetCard({ filterId, taskNumber })());
    /** API - fetch task Report Operator Card */
    dispatch(fetchTaskReportOperatorCard({ filterId, taskNumber })());
    /** API - fetch finding rate by geo region without Tada */
    dispatch(fetchTaskInfoFleetLevelGeoRegionChart({ filterId, taskNumber })());
    /** API - fetch fleet wide number of findings by finding type without Tada */
    dispatch(fetchTaskInfoFleetLevelFindingChart({ filterId, taskNumber })());
    /** API - fetch task info operator level finding by MSN without Tada */
    dispatch(fetchTaskInfoOperatorLevelFindingRateByMsn({ filterId, taskNumber })());
    /** API - fetch operator level number of findings by finding type without Tada */
    dispatch(fetchTaskInfoOperatorLevelFindingChart({ filterId, taskNumber })());
  };
  const fetchIntervalCharts = async () => {
    const IntervalInitReducers = async () => {
      if (isTadaOn) {
        dispatch(precompActualIntervalByTypeTadaInit());
        dispatch(precompCumulativeIntervalByTypeTadaInit());
      }
      dispatch(precompActualIntervalByTypeInit());
      dispatch(precompCumulativeIntervalByTypeInit());
    };
    const IntervalRejectedReducers = async () => {
      if (isTadaOn) {
        dispatch(precompActualIntervalByTypeTadaRejected());
        dispatch(precompCumulativeIntervalByTypeTadaRejected());
      }
      dispatch(precompActualIntervalByTypeRejected());
      dispatch(precompCumulativeIntervalByTypeRejected());
    };
    try {
      /** API - fetch task reports by actual interval and cummulative interval */
      // Initialize dispatch for setting loading true
      IntervalInitReducers();
      const [fcOpsfrResult, fcWwfrResult, fcFdResult,
        fhOpsfrResult, fhWwfrResult, fhFdResult,
        monthOpsfrResult, monthWwfrResult, monthFdResult,
        cummFCOpsfrResult, cummFCWwfrResult, cummFCFdResult,
        cummFhOpsfrResult, cummFhWwfrResult, cummFhFdResult,
        ageOpsfrResult, ageWwfrResult, ageFdResult] = await Promise.all([
        // fc
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: INTERVAL_FC.toLowerCase(), intervalInfo: INTERVAL_INFO_OPSFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: INTERVAL_FC.toLowerCase(), intervalInfo: INTERVAL_INFO_WWFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: INTERVAL_FC.toLowerCase(), intervalInfo: INTERVAL_INFO_FD, isTadaOn,
        })()).unwrap(),
        // fh
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: INTERVAL_FH.toLowerCase(), intervalInfo: INTERVAL_INFO_OPSFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: INTERVAL_FH.toLowerCase(), intervalInfo: INTERVAL_INFO_WWFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: INTERVAL_FH.toLowerCase(), intervalInfo: INTERVAL_INFO_FD, isTadaOn,
        })()).unwrap(),
        // month
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: INTERVAL_MONTH.toLowerCase(), intervalInfo: INTERVAL_INFO_OPSFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: INTERVAL_MONTH.toLowerCase(), intervalInfo: INTERVAL_INFO_WWFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: INTERVAL_MONTH.toLowerCase(), intervalInfo: INTERVAL_INFO_FD, isTadaOn,
        })()).unwrap(),
        // cummulative fc
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: CUMMULATIVE_INTERVAL_FC.toLowerCase(), intervalInfo: INTERVAL_INFO_OPSFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: CUMMULATIVE_INTERVAL_FC.toLowerCase(), intervalInfo: INTERVAL_INFO_WWFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: CUMMULATIVE_INTERVAL_FC.toLowerCase(), intervalInfo: INTERVAL_INFO_FD, isTadaOn,
        })()).unwrap(),
        // cummulative fh
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: CUMMULATIVE_INTERVAL_FH.toLowerCase(), intervalInfo: INTERVAL_INFO_OPSFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: CUMMULATIVE_INTERVAL_FH.toLowerCase(), intervalInfo: INTERVAL_INFO_WWFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: CUMMULATIVE_INTERVAL_FH.toLowerCase(), intervalInfo: INTERVAL_INFO_FD, isTadaOn,
        })()).unwrap(),
        // age
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: CUMMULATIVE_INTERVAL_AGE.toLowerCase(), intervalInfo: INTERVAL_INFO_OPSFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: CUMMULATIVE_INTERVAL_AGE.toLowerCase(), intervalInfo: INTERVAL_INFO_WWFR, isTadaOn,
        })()).unwrap(),
        dispatch(fetchPrecompIntervalByType({
          filterId, taskNumber, operator, intervalType: CUMMULATIVE_INTERVAL_AGE.toLowerCase(), intervalInfo: INTERVAL_INFO_FD, isTadaOn,
        })()).unwrap(),
      ]);

      // set the updated results to the store
      if (isTadaOn) {
        dispatch(precompActualIntervalByTypeTadaFulfilled({ data: updateActualIntervalInStore('actual', [fcOpsfrResult, fcWwfrResult, fcFdResult, fhOpsfrResult, fhWwfrResult, fhFdResult, monthOpsfrResult, monthWwfrResult, monthFdResult]) }));
        dispatch(precompCumulativeIntervalByTypeTadaFulfilled({ data: updateActualIntervalInStore('cumulative', [cummFCOpsfrResult, cummFCWwfrResult, cummFCFdResult, cummFhOpsfrResult, cummFhWwfrResult, cummFhFdResult, ageOpsfrResult, ageWwfrResult, ageFdResult]) }));
      }
      dispatch(precompActualIntervalByTypeFulfilled({ data: updateActualIntervalInStore('actual', [fcOpsfrResult, fcWwfrResult, fcFdResult, fhOpsfrResult, fhWwfrResult, fhFdResult, monthOpsfrResult, monthWwfrResult, monthFdResult]) }));
      dispatch(precompCumulativeIntervalByTypeFulfilled({ data: updateActualIntervalInStore('cumulative', [cummFCOpsfrResult, cummFCWwfrResult, cummFCFdResult, cummFhOpsfrResult, cummFhWwfrResult, cummFhFdResult, ageOpsfrResult, ageWwfrResult, ageFdResult]) }));
    } catch (e) {
      IntervalRejectedReducers();
    }
  };
  const fetchAllDataTada = async () => {
    /** API - fetch task Report World Wide Fleet Card with Tada  */
    dispatch(fetchTaskReportWorldWideFleetCardForTada({ filterId, taskNumber })());
    /** API - fetch task Report Operator Card */
    dispatch(fetchTaskReportOperatorCardForTada({ filterId, taskNumber })());
    /** API - fetch finding rate by geo region without Tada */
    dispatch(fetchTaskInfoFleetLevelGeoRegionChartForTada({ filterId, taskNumber })());
    /** API - fetch fleet wide number of findings by finding type with Tada */
    dispatch(fetchTaskInfoFleetLevelFindingChartForTada({ filterId, taskNumber })());
    /** API - fetch task info operator level finding by MSN with Tada */
    dispatch(fetchTaskInfoOperatorLevelFindingRateByMsnForTada({ filterId, taskNumber })());
    /** API - fetch operator level number of findings by finding type with Tada */
    dispatch(fetchTaskInfoOperatorLevelFindingChartForTada({ filterId, taskNumber })());
  };

  /** check if the task code is 'LSDR_TASK' */
  const isLSDRTask = useMemo(() => {
    return isCalculable?.includes('LSDR_TASK') || false;
  }, [isCalculable]);

  // enable/disable tada toggle based on feature toggle
  const hasEnhancedAnalysisPermission = featureToggleConfig({ name: 'EnhancedAnalysis', userType }, true);
  const hasPermissionAndPremiumUserOrMpe = hasEnhancedAnalysisPermission
    && (checkPermissionByType(PROGRAM_ANALYSIS_MODULE, PREMIUM_PERMISSION) || checkPermissionByType(PROGRAM_ANALYSIS_MODULE, MPE_PERMISSION));
  const setEnhancedAnalysisTadaHandler = () => {
    dispatch(updateEnhancedAnalysisTadaToggle());
  };
  const enhancedAnalysisToggle = (
    <div className="enhanced-toggle-class">
      <SkywiseCrown />
      Enhanced Analysis
      <div style={{ marginTop: '3px' }}>
        <TooltipComponent title="" data={LOCALES.enhance_Info_taskreport_toggle} placement="left" />
      </div>
      <Toggle className="enhanced-toggle" aria-label="enhance-toggle" name="enhance-toggle" data-testid="enhance-toggle" disabled={!hasPermissionAndPremiumUserOrMpe} checked={isTadaOn} onClick={setEnhancedAnalysisTadaHandler} />
    </div>
  );

  useEffect(() => {
    /* make api calls only if filter Id and task number changes */
    const { filterId: prevFilterId, taskNumber: prevTaskNumber } = performance;
    if ((prevFilterId !== filterId || prevTaskNumber !== taskNumber) && taskInfoStatus === 200 && isLSDRTask === false) {
      dispatch(updateTaskReportPerformanceTuning({ filterId, taskNumber, table: DEFAULT_TABLE_DATA }));
      // operator level yearly chart there is no TADA caluculations
      dispatch(fetchTaskInfoOperatorLevelReportReceivedYearly({ filterId, taskNumber, operator })());
      fetchAllDataNonTada();
      fetchIntervalCharts();
    }
  }, [filterId, taskNumber, operator, isLSDRTask, taskInfoStatus]);

  useEffect(() => {
    const { filterId: prevFilterIdTada, taskNumber: prevTaskNumberTada } = performanceTada || {};
    if (initRender) {
      setInitRender(false);
      return;
    }
    if (isTadaOn && (prevFilterIdTada !== filterId || prevTaskNumberTada !== taskNumber) && taskInfoStatus === 200) {
      dispatch(updateTaskReportPerformanceTuning({ filterId, taskNumber, table: DEFAULT_TABLE_DATA }));
      fetchAllDataTada();
      fetchIntervalCharts();
    }
  }, [isTadaOn]);

  // render empty component until the meta data api is successful
  if (taskInfoStatus !== 200) {
    return <> </>;
  }

  return (
    <div className="task-report-analysis-cls">
      {enhancedAnalysisToggle}
      <div>
        <div className="operator-card  task-report-min-screen">
          <CardComponent
            cardHeader={operatorInfoArray.header}
            cardBody={operatorCardBody}
            noOfElementsPerRow={operatorInfoArray.noOfElementsPerRow}
            config={operatorInfoArray.config}
            loading={taskReportAnalysisOperatorCard.loading}
            status={taskReportAnalysisOperatorCard.status}
          />
        </div>
        <div className="operator-fleet-card  task-report-min-screen">
          <CardComponent
            cardHeader={fleetInfoArray.header}
            cardBody={wwfCardBody}
            noOfElementsPerRow={fleetInfoArray.noOfElementsPerRow}
            config={fleetInfoArray.config}
            loading={taskInfoFleetCard.loading}
            status={taskInfoFleetCard.status}
          />
        </div>
      </div>

      <div>
        <QuickLinks quickLinks={taskReportQLinks.quickLinks} title={taskReportQLinks.title} />
      </div>

      <div id="finding-rate-charts" className="multiple-chart-on-same-div chart-title">
        <div className="task-report-charts operator-report-per-year task-report-min-screen">
          <span className="chart-spacing">
            <b>{LOCALES.operator_task_reports_per_year}</b>
          </span>
          <ChartComponent chartData={operatorReportsPerYearChartData} loading={taskInfoOperatorLevelReportReceivedYearly.loading} status={taskInfoOperatorLevelReportReceivedYearly.status} />
        </div>
        <div className="task-report-charts finding-rate-climatic-area task-report-min-screen">
          <span className="chart-spacing">
            {getGraphTitle(LOCALES.finding_rate_by_geo_region, isTadaOn)}
          </span>
          <ChartComponent chartData={findingRateByClimateAreasChartData} loading={findingRateByGeoRegion.loading} status={findingRateByGeoRegion.status} />
        </div>
      </div>

      <div id="operator-finding-type-charts" className="chart-title">
        <span className="chart-spacing">
          {getGraphTitle(LOCALES.operator_finding_type, isTadaOn)}
        </span>
      </div>
      <ChartComponent
        chartData={operatorFindingsAndTypeChartData}
        loading={taskInfoOperatorLevelFindingChart.loading}
        status={taskInfoOperatorLevelFindingChart.status}
      />

      <div id="fleetwide-finding-type-charts" className="chart-title">
        <span className="chart-spacing">
          {getGraphTitle(LOCALES.fleetwide_finding_type, isTadaOn)}
        </span>
      </div>
      <ChartComponent
        chartData={fleetWideFindingsAndTypeChartData}
        loading={fleetWideFindingsByFindingType.loading}
        status={fleetWideFindingsByFindingType.status}
      />

      <div id="task-report-by-msn" className="chart-title">
        <span className="chart-spacing">
          {getGraphTitle(LOCALES.task_report_finding_rate_msn, isTadaOn)}
        </span>
      </div>
      {taskInfoOperatorLevelFindingRateByMsn.loading && (
      <div className="msn-loader-card">
        <div className="msn-loader-center">
          <SpinnerComponent size="small" label="" />
        </div>
      </div>
      )}
      {['succeeded', 'failed'].includes(taskInfoOperatorLevelFindingRateByMsn.status) && (
        <RangeChart
          rangeSelector="slider"
          chartData={taskReportAndFindingRateByMSNChartData}
          xLabel="MSN"
          status={taskInfoOperatorLevelFindingRateByMsn.status}
        />
      )}

      <div id="task-report-by-actual-int" className="chart-title">
        <span className="chart-spacing">
          {getGraphTitle(LOCALES.finding_type_per_actual_interval_title, isTadaOn)}
        </span>
      </div>

      {/* tabs, multichartdata, and multiChartDropDownData2 should be in the same order */}
      <ChartComponent
        tabs={tabsActualInterval.tabs}
        selectedTabIndex={actualIntervalDataIndex}
        chartData={taskReportByActualIntervalFHChartData}
        multiChartData={[taskReportByActualIntervalFHChartData, taskReportByActualIntervalFCChartData, taskReportByActualIntervalMonthChartData]}
        multiChartDropDownData2={[taskReportByActualIntervalFHDropDownData, taskReportByActualIntervalFCDropDownData, taskReportByActualIntervalMonthDropDownData]}
        status={actualIntervalStatus}
        loading={actualIntervalLoading}
      />

      <div id="task-report-by-cumulative-int" className="chart-title">
        <span className="chart-spacing">
          {getGraphTitle(LOCALES.finding_type_and_finding_rate_per, isTadaOn)}
        </span>
      </div>

      {/* tabs, multichartdata, and multiChartDropDownData2 should be in the same order */}
      <ChartComponent
        tabs={tabsCumulativeInterval.tabs}
        selectedTabIndex={cummulativeIntervalDataIndex}
        chartData={taskReportByCummulativeIntervalAgeChartData}
        multiChartData={[taskReportByCummulativeIntervalAgeChartData, taskReportByCummulativeIntervalFHChartData, taskReportByCummulativeIntervalFCChartData]}
        multiChartDropDownData2={[taskReportByCummulativeIntervalAgeDropDownData, taskReportByCummulativeIntervalFHDropDownData, taskReportByCummulativeIntervalFCDropDownData]}
        status={cummulativeIntervalStatus}
        loading={cummulativeIntervalLoading}
      />
      <TaskReportTable operator={operator || ''} />
      <ScrollToTopButton />
    </div>
  );
}
