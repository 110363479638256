import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import { rootReducer } from '../root_reducer/appReducer';

const logger = createLogger({
  // to show the difference between what changed in state
  diff: true,

  // to log time
  duration: true,
  timestamp: true,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  predicate: (getState, action) => process.env.NODE_ENV !== 'production',

  // custom colors for each log
  colors: {
    title: () => '#0f1842',
    prevState: () => '#de6f0d',
    action: () => '#6e13ab',
    nextState: () => '#1a9134',
  },
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {tasks: tasksState, user: userState, maintanenceTask: maintanenceTaskState, ...}
export type AppDispatch = typeof store.dispatch;
